import React, { useState } from 'react';
import emailjs from 'emailjs-com';

// Modal Component
const Modal = ({ isOpen, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    }}>
      <div style={{
        padding: 20,
        background: "#fff",
        borderRadius: 5,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.26)",
        zIndex: 1000,
        maxWidth: "400px",
        textAlign: "center",
      }}>
        <p>{message}</p>
        <button onClick={onClose} style={{ padding: "10px 20px", marginTop: 10 }} className="btn btn-custom btn-lg">
          Close
        </button>
      </div>
    </div>
  );
};

// Initial state for the form
const initialState = {
  name: "",
  email: "",
  message: "",
};

// Contact Component
export const Contact = () => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_833nrpw", "template_510v0oi", e.target, "teAKaK9EKrwES4FNa")
      .then(
        (result) => {
          console.log(result.text);
          setModalMessage('Message sent successfully!');
          setShowModal(true);
          clearState();
        },
        (error) => {
          console.log(error.text);
          setModalMessage('Failed to send message. Please try again later.');
          setShowModal(true);
        }
      );
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="section-title">
            <h2>Get In Touch</h2>
            <p>Please fill out the form below to send us an email and we will get back to you as soon as possible.</p>
          </div>
          <form name="sentMessage" validate onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows="4"
                placeholder="Tell us about what you need"
                required
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit" className="btn btn-custom btn-lg">
              Send Message
            </button>
          </form>
        </div>
      </div>
      <Modal isOpen={showModal} message={modalMessage} onClose={handleCloseModal} />
    </div>
  );
};
