import React, { useEffect } from "react";

export const Header = (props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.fbgcdn.com/embedder/js/ewm2.js";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a href="#contact" className="btn btn-custom btn-lg page-scroll">
                  Contact Us
                </a>
                {/* <span className="btn btn-custom btn-lg page-scroll" data-glf-cuid="5084940d-3fae-452e-9e46-ec40a1d31c4f" data-glf-ruid="4cf3f867-211b-48c1-b068-49088784123a">
                  See MENU & Order
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
